import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import LoadScript from 'vue-plugin-load-script';
import VueAnalytics from 'vue-analytics'
import Meta from 'vue-meta';
import VueYoutubeEmbed from 'vue-youtube-embed'

Vue.config.productionTip = false

Vue.use( Vuex );
Vue.use( Meta );
Vue.use( LoadScript );
Vue.use( VueAnalytics, { 
  id : 'UA-135699665-1',
  router
} );
Vue.use( VueYoutubeEmbed );


const store = new Vuex.Store({
  state : {
    isStreamLive: false,
  },
  mutations :{
    async checkStreamStatus( state ){
      
      const fetchUrl = "https://api.twitch.tv/helix/streams?user_login=proxym4";
      
      const res = await fetch( fetchUrl, {
        method: "get",
        headers: new Headers({
          'Authorization': 'Bearer yv25ivuji9ty1qo3y2ub1cjr0oetrk',
          'Client-ID': '5d22ijkf4fazqe2qgcfwdmnpnq113i'
        })
      });
      
      const stream = await res.json();

      state.isStreamLive = stream.data.length === 1;
    }
  }
});

new Vue({
  router,
  render: h => h(App),
  store,
}).$mount('#app')
