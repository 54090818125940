<template>
  <div id="app">
    <nav>
      <router-link to="/" v-bind:class="{ liveOn : $store.state.isStreamLive }" >
        <span v-if="$store.state.isStreamLive">🔴 Je suis en live !</span>
        <span v-else >Home</span>
      </router-link>
      <router-link to="/hades-roulette">Hades Roulette</router-link>
      <!-- | router-link to="/games">Games</router-link-->
    </nav>
    <router-view/>
  </div>
</template>

<script>
  export default {
    name: "App",
    data() {
      return {
        isStreamLive : false
      }
    },
    methods : {
      checkStreamStatus(){
        this.$store.commit('checkStreamStatus');
        this.isStreamLive = this.$$store.state.isStreamLive;
      }
    },
  }
</script>

<style lang="scss">
@import "./scss/variables.scss";

body {
  background: #ecf0f1;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 1280px;
  margin: 0 auto;
}

body {
  padding: 0;
  margin: 0;
}

nav {
  display: flex;
  justify-content: center;
  background: #2c3e50;

  a {
    display: block;
    padding: 15px;
    font-weight: bold;
    color: #ecf0f1;
    text-decoration: none;

    &.router-link-exact-active {
      color: #3498db;
    }

    &.liveOn {
      background: $main-color;
      color: #fff;
      animation: blink 1.5s ease-in infinite;

      &:hover {
        background: $main-color;
        animation: 0;
      }
    }
  }
}

@keyframes blink {
  0% { background: $main-color; }
  50% { background:#a2daff; }
  100% { background: $main-color; }
}
</style>
