<template>
  <div class="home">
    <VueTwitchPlayer class="video-responsive"  v-if="$store.state.isStreamLive" :channel="channel" :width="width" :height="height" />
    <youtube class="video-responsive" v-else :video-id="videoId" player-width="1280" player-height="750" :player-vars="{autoplay: 1}"></youtube>
  </div>
</template>

<script>
// @ is an alias to /src
import VueTwitchPlayer from 'vue-twitch-player'

let ROOT_PATH = 'https://proxym4.tv';

export default {
  name: 'Home',
  components: {
    VueTwitchPlayer,
  },
  data() {
    return {
      channel : 'proxym4',
      width: "1366",
      height: "768",
      metaImg : ROOT_PATH + require('../assets/hades/hades-roulette.jpg'),
      videoId: 'KLahicT3Xto'
    }
  },
  metaInfo(){
    return {
      title : 'Proxym4',
      meta : [
        /* TWITTER */
        { name: 'twitter:card', content: 'summary'},
        { name: 'twitter:title', content: 'Proxym4.tv'},
        { name: 'twitter:description', content: '1/4 de finaliste ZLAN, stream du mardi au jeudi de 18 à 22h'},
        { name: 'twitter:image', content: this.metaImg },
        /* FACEBOOK */
        { property: 'og:title', content: 'Proxym4.tv' },
        { property: 'og:site_name', content: 'Proxym4.tv' },
        { property: 'og:type', content: 'website' },
        { property: 'og:image', content: this.metaImg },
        { property: 'og:description', content: '1/4 de finaliste ZLAN, stream du mardi au jeudi de 18 à 22h'},
      ]
    }
  },
  methods : {},
  mounted(){
    this.$store.commit('checkStreamStatus');
  }
}
</script>

<style lang="scss">
  .video-responsive { 
    overflow:hidden; 
    padding-bottom:56.25%; 
    position:relative; 
    height:0;
  }

  .video-responsive iframe {
    left:0; 
    top:0; 
    height:100%;
    width:100%;
    position:absolute;
  }
</style>